import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { BASE_API } from "../../shared/constants/app";
import { useEffect, useState } from "react";
import { loginCustomer, loginGoogleCustomer } from "../../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import { loginSuccess } from "../../redux-setup/reducers/auth";
import { loginGoogleSuccess } from "../../redux-setup/reducers/auth";

function Login_new() {
	const [inputsCustomer, setInputsCustomer] = useState({});
	const [errorLogin, setErrorLogin] = useState(false);
	const [userGoogle, setUserGoogle] = useState({});

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const changeInputsCustomer = (e)=>{ 
	  const {name, value} = e.target;
	  return setInputsCustomer({...inputsCustomer, [name]: value});
	}
	const clickLogin = (e)=>{
	  e.preventDefault();
	  loginCustomer(inputsCustomer)
		  .then(({data})=>{
			  dispatch(loginSuccess(data));
			  navigate("/Login")
		  })
		  .catch((error)=>setErrorLogin(true));
	}

	const clickBtnGoogle =  () => {
		window.open(
			`${BASE_API}/auth/google/callback`,
			"_self"
		);
		navigate("/Login"); 
	};

	// useEffect(()=>{

	// },[loggedGoogle]); 

	const clickBtnFacebook = () => {

	};
	return (
		<div className={styles.container}>
			<h1 className={styles.heading}>Log in Form</h1>
			<div className={styles.form_container}>
				<div className={styles.left}>
					<img className={styles.img} src="./images/login.jpg" alt="login" />
				</div>
				<div className={styles.right}>
					<h2 className={styles.from_heading}>Members Log in</h2>
					{	errorLogin
           				&& ( <div className="alert alert-danger text-center"> 
							Thông tin Email hoặc Password không hợp lệ! </div> )
        			}
					<input name="email" type="text" className={styles.input} placeholder="Email" 
						onChange={changeInputsCustomer} value={inputsCustomer.email || ""}/>
					<input name="password" type="text" className={styles.input} placeholder="Password" 
						onChange={changeInputsCustomer} value={inputsCustomer.password || ""}/>

					<button onClick={clickLogin} className={styles.btn}>Log In</button>

					<p className={styles.text}>or</p>
					<button className={styles.google_btn} onClick={clickBtnGoogle}>
						<img src="./images/google.png" alt="google icon" />
						<span>Sing in with Google</span>
					</button>
					<p className={styles.text}>
						Forgot Password ? <Link to="/password-reset">Click Here</Link>
					</p>
				</div>
			</div>
		</div>
	);
}

export default Login_new;
