import { Link } from "react-router-dom";
import styles from "./styles.module.css";

import { useState } from "react";
import { SendPasswordLinkCustomer } from "../../services/Api";

import { useNavigate} from "react-router-dom";



function PasswordReset() {
	const [inputsCustomer, setInputsCustomer] = useState({});
	const [errorLogin, setErrorLogin] = useState(false);

	const navigate = useNavigate();
	const changeInputsCustomer = (e)=>{ 
	  const {name, value} = e.target;
	  return setInputsCustomer({...inputsCustomer, [name]: value});
	}
	const clickLogin = (e)=>{
	  e.preventDefault();
	  SendPasswordLinkCustomer(inputsCustomer)
		  .then(({data})=>{
            // setTimeout(()=>{
            //     navigate("/Login"); 
            // },5000)
		  })
		  .catch((error)=>setErrorLogin(true));
	}

	return (
		<div className={styles.container}>
			<h1 className={styles.heading}>Reset Password</h1>
			<div className={styles.form_container}>
				<div className={styles.left}>
					<img className={styles.img} src="./images/login.jpg" alt="login" />
				</div>
				<div className={styles.right}>
					<h2 className={styles.from_heading}>Enter Your Email</h2>
					{	errorLogin
           				&& ( <div className="alert alert-danger text-center"> 
							Thông tin Email hoặc Password không hợp lệ! </div> )
        			}
                    <input name="email" type="text" className={styles.input} placeholder="Email" 
                        onChange={changeInputsCustomer} value={inputsCustomer.email || ""}/>

                    <button onClick={clickLogin} className={styles.btn}>Send</button>
                    <p className={styles.text}>
						Return Login? <Link to="/Login">Click Here</Link>
					</p>

				</div>
			</div>
		</div>
	);
}

export default PasswordReset;
