import { getSetting } from "../../../services/Api";
import { useEffect, useState } from "react";
import { getImageProduct } from "../../ultils";

const Banner = () => {
    const [slider, setSlider] = useState([]);
    const [banner_r, setBanner_r] = useState([]);
    const [banner_b, setBanner_b] = useState([]);

    useEffect(()=>{
        getSetting().then(({data})=>{
            setSlider(data.data.slider)
            setBanner_r(data.data.banner_r)
            setBanner_b(data.data.banner_b)
        
        });
    },[])
    return (
        <>
            <div>
                <div id="homepage-menu" className="row">
                    <div id="banner-left" className="col-lg-3 col-md-0 col-sm-12">
                        <div id="banner-left-items">
                            <h4>Sinh viên thực hiện:</h4>
                            <p><i className="fas fa-user-graduate" />Đỗ Văn Minh - 20195093</p>
                            <p><i className="fas fa-user-graduate" />Vũ Minh Tân - 20195170</p>
                            <p><i className="fas fa-user-graduate" />N.T.H Duy - 20194989</p>
                            <h4>Giảng Viên Hướng Dẫn:</h4>
                            <p><i className="fas fa-user-tie" />TS. Lý Hoàng Hiệp</p>
                        </div>
                    </div>
                    <div id="slider" className="col-lg-6 col-md-12 col-sm-12">
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                                <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                                <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                                <li data-target="#carouselExampleIndicators" data-slide-to={3} />
                                <li data-target="#carouselExampleIndicators" data-slide-to={4} />
                            </ol>
                            <div className="carousel-inner">
                                {
                                    slider.map((item, index)=>                                       
                                        <div className={index==0 ? "carousel-item active" : "carousel-item"} >
                                            <img src={getImageProduct(item.url_image)} className="d-block w-100" alt="..." />
                                        </div>
                                    )
                                }
                            </div>
                            <button className="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="sr-only">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="sr-only">Next</span>
                            </button>
                        </div>
                    </div>
                    <div id="banner-right" className="col-lg-3 col-md-3 col-sm-12">
                    {
                        banner_r.map((item, index)=>                                       
                            <div className="banner-right-item banner-hover">
                                <a target="_blank" href={item.link}> <img className="img-fluid" src={getImageProduct(item.url_image)} /> </a>
                            </div>
                        )
                    }
                    </div>
                </div>
                <div id="banner-bottom" className="row">
                {
                    banner_b.map((item, index)=>                                       
                        <div className="banner-bottom-item banner-hover col-lg-4 col-md-0 col-sm-12">
                            <a target="_blank" href={item.link}> <img className="img-fluid" src={getImageProduct(item.url_image)} /> </a>
                        </div>
                    )
                }  
                </div>
            </div>

        </>
    )
}
export default Banner;
