const Footer = () => {
  return (
    <>
      <div>
        <div id="footer-t">
          <div className="container">
            <div className="row">
              <div id="logo-t" className="col-lg-3 col-md-6 col-sm-12">
                <h2> <a href="#"><img src="./images/logohoqua.jpg" /> </a></h2>
                <p>
                  Đồ Án : "Nghiên cứu phát triển hệ thống phân loại hàng hoá thông minh và lưu kho tự động ứng dụng trong thương mại điện tử." Nhóm gồm 3 thành viên
                  ___Đỗ Văn Minh-20195093    ____Nguyễn Trần Hoàng Duy-20194989    ____Vũ Minh Tân-20195170.
                  Là thành viên lớp Cơ Điện Tử 06 Khoá K64 - Đại Học Bách Khoa Hà Nội !!!
                </p>
              </div>
              <div id="address" className="col-lg-3 col-md-6 col-sm-12">
                <h3>Địa chỉ Shop</h3>
                <p>Kí Túc Xá B10, B3, B8 --Đại Học BKHN</p>
                <p>Hồ Tây - Nơi chôn vùi kỷ niệm ! </p>
              </div>
              <div id="services" className="col-lg-3 col-md-6 col-sm-12">
                <h3>Dịch vụ</h3>
                <p>Bán Hoa quả</p>
                <p>Nhận chăm sóc các bạn nữ mới nhớn, 18-25</p>
              </div>
              <div id="hotline" className="col-lg-3 col-md-6 col-sm-12">
                <h3>Hotline</h3>
                <p>Phone Sale: 0325333960</p>
                <p>Email: m0325333960@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-b">
          <div className="container">
            <p>2024 @ Đại Học Bách Khoa Hà Nội. Đồ Án Tốt Nghiệp.  Đỗ Văn Minh-20195093  Vũ Minh Tân-20195170  Nguyễn Trần Hoàng Duy-20194989. </p>
          </div>
        </div>
      </div>

    </>
  );
};
export default Footer;
