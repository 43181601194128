import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { BASE_API } from "../constants/app";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginGoogleSuccess, loginSuccess} from "../../redux-setup/reducers/auth";

const CheckLoggedNew= (OriginComponent)=>{
    function ExtendsComponent (){
        const [user, setUser] = useState(null);
        const dispatch = useDispatch();
        
        const getUser = async () => {
            try {
                const url = `${BASE_API}/customer/loginGoogle/success`;
                const { data } = await axios.get(url, { withCredentials: true });
                setUser(data.user);
            } catch (err) {
                console.log(err);
            }
        };
    
        useEffect(() => {
            getUser();
        }, []);

        if(user){
            dispatch(loginGoogleSuccess(user))
            console.log(user)  
        }
        const loggedGoogle = useSelector(({ Auth }) => Auth.login.loggedGoogle);
        const logged = useSelector(({ Auth }) => Auth.login.logged);
        console.log(loggedGoogle)
        return loggedGoogle || logged ? <Navigate to="/"/> : <OriginComponent/>
    }
    return ExtendsComponent;
}
export default CheckLoggedNew; 
// mở rộng logic code cho component cta muốn  
//Login thì ko vào lại Login và Register