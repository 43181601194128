import { Link } from "react-router-dom";
import styles from "./styles.module.css";

import { useState } from "react";
import { ChangePasswordCustomer} from "../../services/Api";
import { useNavigate} from "react-router-dom";
import { useParams } from "react-router-dom";


function ForgotPassword() {
	const [inputsCustomer, setInputsCustomer] = useState({});
	const [errorLogin, setErrorLogin] = useState(false);

	const navigate = useNavigate();
    const { id } = useParams();
    const { token } = useParams();

	const changeInputsCustomer = (e)=>{ 
	  const {name, value} = e.target;
	  return setInputsCustomer({...inputsCustomer, [name]: value});
	}
	const clickSendNewPassword = (e)=>{
	  e.preventDefault();
	  ChangePasswordCustomer(id,token,inputsCustomer)
		  .then(({data})=>{
            setTimeout(()=>{
                navigate("/Login"); 
            },140000)
		  })
		  .catch((error)=>setErrorLogin(true));
	}

	return (
		<div className={styles.container}>
			<h1 className={styles.heading}>New Password</h1>
			<div className={styles.form_container}>
				<div className={styles.left}>
					<img className={styles.img} src="./images/login.jpg" alt="login" />
				</div>
				<div className={styles.right}>
					<h2 className={styles.from_heading}>Enter New Password</h2>
					{/* {	errorLogin
           				&& ( <div className="alert alert-danger text-center"> 
							Thông tin Email hoặc Password không hợp lệ! </div> )
        			} */}
                    <input name="password" type="text" className={styles.input} placeholder="New Password" 
                        onChange={changeInputsCustomer} value={inputsCustomer.password || ""}/>

                    <button onClick={clickSendNewPassword} className={styles.btn}>Send</button>
                    <p className={styles.text}>
						Return Login? <Link to="/Login">Click Here</Link>
					</p>

				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
